import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/episodes/2024/207/episode.md";
import * as React from "react";
import Layout from "../components/layout";
import {title, published_date, content} from "./episode.module.css";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {Seo} from "../components/seo";
import {useSiteMetadata} from "../hooks/use-site-metadata";
const EpisodePage = ({pageContext, children}) => {
  const {episode, casts} = pageContext;
  const image = getImage(episode.frontmatter.thumbnail);
  return React.createElement(Layout, null, React.createElement(GatsbyImage, {
    image: image,
    alt: episode.frontmatter.title
  }), React.createElement("div", {
    className: published_date
  }, " No.", episode.frontmatter.uid, ", ", episode.frontmatter.published_date, " 공개"), React.createElement("h1", {
    className: title
  }, episode.frontmatter.title), React.createElement("div", null, React.createElement("audio", {
    src: episode.frontmatter.enclosure.url,
    preload: "none",
    controls: "controls"
  }, React.createElement("track", {
    src: "",
    kind: "captions",
    srcLang: "en",
    label: "english_captions"
  }))), React.createElement("div", null, React.createElement("h2", null, "출연자"), React.createElement("ul", null, episode.frontmatter.cast.map(cast => {
    const splitStrs = cast.split(",", 2);
    if (splitStrs.length >= 2) {
      return React.createElement("li", {
        key: splitStrs[0]
      }, React.createElement("a", {
        href: splitStrs[1]
      }, splitStrs[0]));
    } else {
      const link = casts[cast];
      if (link) {
        return React.createElement("li", {
          key: cast
        }, React.createElement("a", {
          href: link
        }, cast));
      } else {
        return React.createElement("li", {
          key: cast
        }, cast);
      }
    }
  }))), React.createElement("div", {
    className: content
  }, React.createElement("h2", null, "쇼 노트"), children), React.createElement("div", null, React.createElement("h2", null, "추가 정보"), React.createElement("ul", null, episode.frontmatter.recording_date && React.createElement("li", null, "녹음 날짜 : ", episode.frontmatter.recording_date), React.createElement("li", null, "길이 : ", episode.frontmatter.duration))));
};
EpisodePage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(EpisodePage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({pageContext}) => {
  const {episode, pagePath} = pageContext;
  const metaTitle = "44bits: " + episode.frontmatter.uid + ": " + episode.frontmatter.title;
  const {siteUrl} = useSiteMetadata();
  return React.createElement(Seo, {
    title: metaTitle,
    pathname: pagePath
  }, episode.frontmatter.thumbnail && React.createElement("meta", {
    property: "og:image",
    content: siteUrl + episode.frontmatter.thumbnail.childImageSharp.fixed.src
  }));
};
